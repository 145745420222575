import { lazy } from "react"
const Login = lazy(()=> import(/*webpackChunkName:'routes' */ "../pages/login"))
const SignUp = lazy(()=> import(/*webpackChunkName:'routes' */  "../pages/signup"))
const Request = lazy(()=> import(/*webpackChunkName:'routes' */  "../pages/request"))
const Home = lazy(()=> import(/*webpackChunkName:'routes' */  "../pages/home"))
const Gcion = lazy(()=> import(/*webpackChunkName:'routes' */  "../pages/Gcoin"))
const Setting = lazy(()=> import(/*webpackChunkName:'routes' */  "../pages/setting"))
const Activity = lazy(()=> import(/*webpackChunkName:'routes' */  "../pages/activity"))
const Pay = lazy(()=> import(/*webpackChunkName:'routes' */  "../pages/pay"))
const Details = lazy(()=> import(/*webpackChunkName:'routes' */  "../pages/details"))
const Stripe = lazy(()=> import(/*webpackChunkName:'routes' */  '../pages/stripe'))
const Wallets = lazy(()=> import(/*webpackChunkName:'routes' */  "../pages/wallets"))
const Account = lazy(()=> import(/*webpackChunkName:'routes' */  "../pages/account"))
const Shop = lazy(()=> import(/*webpackChunkName:'routes' */  "../pages/shop"))
const HomeNew = lazy(()=> import(/*webpackChunkName:'routes' */  "../pages/home-new"))

export let routerArr = [
    {
        path:'/',
        component:SignUp
    },
    {
        path:'/login',
        component:Login
    },
    {
        path:'/stripe',
        component:Stripe
    },
    {
        path:'/home',
        component: HomeNew,
    },
    {
        path:'activity',
        component:Activity,
        children:[
            {
                path:'details/:id',
                component:Details
            }
        ]
    },
    {
        path:'shop',
        component:Shop,
    },
    {
        path:'request',
        // component:Request,
        redirect:'/gcoin/request'
    },
    {
        path:'gcoin',
        component:Gcion,
        children: [{
            path:'request',
            component:Request
        }]
    },
    {
        path:'setting',
        component:Setting,
        children:[
            {
                path:'account',
                component:Account
            },
            {
                path:'wallets',
                component:Wallets
            }
        ]
    },
    {
        path:'/pay',
        component:Pay,
        children:[
            {
                path:'home',
                component:Home,
            },
            // {
            //     path:'shop',
            //     component:Shop,
            // },
            // {
            //     path:'request',
            //     component:Request,
            // },
            // {
            //     path:'Gcoin',
            //     component:Gcion,
            // },
            // {
            //     path:'setting',
            //     component:Setting,
            //     children:[
            //         {
            //             path:'account',
            //             component:Account
            //         },
            //         {
            //             path:'wallets',
            //             component:Wallets
            //         }
            //     ]
            // },
            {
                path:'activity',
                component:Activity,
                children:[
                    {
                        path:'details/:id',
                        component:Details
                    }
                ]
            },
        ]
    },
]
