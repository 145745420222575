import axios from "axios";
import { message } from 'antd';
import { constants } from '../src/assets/js/constant'
import Cookies from "js-cookie";
let request = axios.create({
    baseURL: process.env.REACT_APP_API_URL + '/v1',
    timeout: 30 * 1000,
    responseType: 'json',
})

export const getJumpPath = () => {
    return (process.env.NODE_ENV !== 'production' ? '/login' : process.env.REACT_APP_URL + '/login') + ('?redirect=' + window.location.href);
}

//请求拦截器
request.interceptors.request.use((config) => {
    let token = Cookies.get('token')
    if (token) {
        config.headers['Authorization'] = token
    } else if (!token && (constants.whiteList.indexOf(config.url) > -1)) {
        console.log('no token needed');
    } else {
        const isDev = process.env.NODE_ENV === 'development';
        Cookies.remove("token", {
            domain: !isDev ? process.env.REACT_APP_SUFFIX : undefined,
        });
        window.location.href = getJumpPath();
    }
    return config
})

//响应拦截器
request.interceptors.response.use((config) => {
    if (config.data.msg && config.data.msg === 'expire token') {
        const isDev = process.env.NODE_ENV === 'development';
        Cookies.remove("token", {
            domain: !isDev ? process.env.REACT_APP_SUFFIX : undefined,
        });
        window.location.href = getJumpPath();
    }
    if (config.data.code !== 0 && config.config.url !== 'user/order_history' && config.config.url !== 'user/order_pay' && config.config.url !== 'user/vchain/open_account' && config.config.url !== '/nft-search') {
        // 如果tokne过期更新token
        if ([1124, 1123].includes(config.data.code)) {
            const isDev = process.env.NODE_ENV === 'development';
            Cookies.remove("token", {
                domain: !isDev ? process.env.REACT_APP_SUFFIX : undefined,
            });
            if (config.data.code === 1123) {
                localStorage.clear();
                window.location.href = getJumpPath();
            } else {
                Cookies.set('token', config.data.data, {
                    expires: 7,
                    domain: !isDev ? process.env.REACT_APP_SUFFIX : undefined,
                });
                window.location.reload();
            }
        } else if (config.data.msg !== 'bind address owner') {
            message.error(config.data.msg, 3)
        }
    }
    return config
    //统一处理响应
}, (error) => {
    message.error('Request failed please try again later.', 3)
})

export default request
