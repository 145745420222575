import React from 'react';
import ReactDOM from 'react-dom/client';

import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux'
import { Web3ReactProvider } from '@web3-react/core';
import Web3 from 'web3'
// import { MetaMaskProvider } from '@metamask/sdk-react';
import {web3} from '../src/api/web3'
import AuthRoute from './components/auth';
import store from './store';
import './rem'

// // 获取库的函数，这里使用了 ethers.js 作为 Web3 提供者
const getLibrary = () => {
    const library = web3;
    // const library = new Web3(new Web3.providers.HttpProvider('https://node4.mainnet.gon.network'));
    library.pollingInterval = 1000;
    return library;
};
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Provider store={store}>

        <Web3ReactProvider getLibrary={getLibrary}>
        <BrowserRouter>
        {/* <MetaMaskProvider debug={false} sdkOptions={{
          checkInstallationImmediately: false,
          dappMetadata: {
            name: "Demo React App",
            url: window.location.host,
          }
        }}> */}
          <AuthRoute>
          <App />
          </AuthRoute>
        {/* </MetaMaskProvider> */}
        </BrowserRouter>
        </Web3ReactProvider>
    </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
