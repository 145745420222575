import React from 'react'
import {Navigate, useLocation} from "react-router-dom";
import Cookies from 'js-cookie';
import {userInfo} from '../api/user'
import { useSelector, useDispatch } from 'react-redux'

const loginRoute = '/login'
const indexRoute = '/shop'
// 路由表白名单
const allowList = ['/login', '/register','/activity/details']

const AuthRoute = (props) => {
    const location = useLocation();
    let dispatch = useDispatch()
    // children 为子组件
    const {children} = props
    let token = Cookies.get('token')
    if (token && token !== 'undefined') {
        userInfo().then(({data}) => {
            if(data) {
                const userInfo = data.data;
                localStorage.setItem('userInfo', JSON.stringify(data.data))
                dispatch({
                    type:'userInfo/changeUserInfo',
                    payload: userInfo
                })
                dispatch({
                    type:'userInfo/changeEmail',
                    payload: userInfo.email
                })
            }
        });
        // 有 token 的状态下禁止用户回到登录页，重定向到首页
        if (location.pathname === loginRoute || location.pathname === '/pay') {
            return <Navigate to={indexRoute}></Navigate>
        } else {
            // 其他路由均可正常跳转
            return <>{children}</>
        }
    } else {
        // 无 token 的状态下，如果要跳转的路由是白名单中的路由，正常跳转
        if (allowList.includes(location.pathname || '')) {
            return <>{children}</>
        } else if(location.pathname.indexOf('/activity/details/' > -1)){
            return <>{children}</>
        } else {
            // 无 token 且非白名单路由，重定向至登录页
            return <Navigate to={loginRoute}></Navigate>
        }
    }
}
export default AuthRoute
