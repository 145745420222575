import Web3 from "web3";
import { message } from "antd";

const erc20ABI = [
    {
        "constant": true,
        "inputs": [
            {
                "name": "_owner",
                "type": "address"
            }
        ],
        "name": "balanceOf",
        "outputs": [
            {
                "name": "balance",
                "type": "uint256"
            }
        ],
        "type": "function"
    }
];

const nftContractABI = [
    {
        "constant": true,
        "inputs": [
            {
                "internalType": "address",
                "name": "owner",
                "type": "address"
            },
            {
                "internalType": "address",
                "name": "operator",
                "type": "address"
            }
        ],
        "name": "isApprovedForAll",
        "outputs": [
            {
                "internalType": "bool",
                "name": "",
                "type": "bool"
            }
        ],
        "payable": false,
        "stateMutability": "view",
        "type": "function"
    },
    {
        "constant": false,
        "inputs": [
            {
                "internalType": "address",
                "name": "to",
                "type": "address"
            },
            {
                "internalType": "bool",
                "name": "approved",
                "type": "bool"
            }
        ],
        "name": "setApprovalForAll",
        "outputs": [],
        "payable": false,
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "constant": true,
        "inputs": [
            {
                "internalType": "uint256",
                "name": "tokenId",
                "type": "uint256"
            }
        ],
        "name": "getApproved",
        "outputs": [
            {
                "internalType": "address",
                "name": "",
                "type": "address"
            }
        ],
        "payable": false,
        "stateMutability": "view",
        "type": "function"
    },
    {
        "constant": true,
        "inputs": [
            {
                "internalType": "uint256",
                "name": "tokenId",
                "type": "uint256"
            }
        ],
        "name": "ownerOf",
        "outputs": [
            {
                "internalType": "address",
                "name": "",
                "type": "address"
            }
        ],
        "payable": false,
        "stateMutability": "view",
        "type": "function"
    },
    {
        "constant": false,
        "inputs": [
            {
                "internalType": "address",
                "name": "to",
                "type": "address"
            },
            {
                "internalType": "uint256",
                "name": "tokenId",
                "type": "uint256"
            }
        ],
        "name": "approve",
        "outputs": [],
        "payable": false,
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "constant": true,
        "inputs": [
            {
                "internalType": "address",
                "name": "owner",
                "type": "address"
            }
        ],
        "name": "balanceOf",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "payable": false,
        "stateMutability": "view",
        "type": "function"
    }
]

export const web3 = new Web3(new Web3.providers.HttpProvider('https://node4.mainnet.gon.network'));


//获取余额
export function getBalance(address, Gaddress) {
    const gcoinContractAddress = Gaddress;
    const gcoinContract = new web3.eth.Contract(erc20ABI, gcoinContractAddress);

    return new Promise((resolve, reject) => {
        gcoinContract.methods.balanceOf(address).call((error, result) => {
            if (error) {
                reject(error)
                return;
            }
        }).then(res => {
            let balance = web3.utils.fromWei(res, 'ether')
            if (balance === '0.') {
                resolve('0')
            } else {
            }
            resolve(balance)

        }).catch(error => console.log(reject(error)));
    })
}

export const detectCurrentProvider = () => {
    let provider;
    if (window.ethereum) {
        provider = window.ethereum;
    } else if (window.web3) {
        provider = window.web3.currentProvider;
    } else {
        message.error(
            'Non-Ethereum browser detected. You should consider trying MetaMask!'
            , 3);
    }
    return provider;
};

export function getAccount(callback) {
    const currentProvider = detectCurrentProvider();
    console.log('currentProvider = ', currentProvider)
    window.ethereum.request({ method: 'eth_requestAccounts' }).then((accounts) => {
        console.log('accounts = ', accounts, web3.eth.defaultAccount)
        callback(accounts[0])
    }).catch(error => {
        console.log()
        if (Number(error.code) === -32002) {
            message.error(error.message, 3)
            window.ethereum.on('accountsChanged', function (accounts) {
                // 当用户切换账户时，更新页面上的地址
                console.log('accountsChanged', accounts[0], web3.eth.defaultAccount)
                callback(accounts[0])
            });
        }

    })
}

export function personalSign(address, msg) {
    return window.ethereum.request({
        method: "personal_sign",
        params: [msg, address],
    });
}

export function setApproval(account, chainId, nftContractAddress, nftInventoryAddress) {
    // 根据不同的chainID，选择不同的provider 支持10024和96
    let url = '';
    switch (chainId) {
        case 10024:
            url = 'https://node4.mainnet.gon.network';
            break;
        case 96:
            url = 'https://rpc.ankr.com/bsc/0dc84669bb53b3870ee0089d703578ea9cdae259fc2a38564cd869514cd9347c';
            break;
        default:
            url = 'https://node1.mainnet.gon.network';
            break;
    }

    const web3 = new Web3(new Web3.providers.HttpProvider(url));
    const tokenContract = new web3.eth.Contract(nftContractABI, nftContractAddress);
    const setApprovalForAllAbi = tokenContract.methods
        .setApprovalForAll(
            nftInventoryAddress,
            true
        )
        .encodeABI();
    return new Promise((resolve, reject) => {
        window.ethereum.request({
            method: 'eth_sendTransaction',
            params: [
                {
                    from: account,
                    to: nftContractAddress,
                    data: setApprovalForAllAbi
                },
            ],
        }).then(res => {
            resolve(res)
        }).catch(error => console.log(reject(error)));
    })
}
