import style from './App.module.less';
import { Routes } from 'react-router-dom';
import { lazy, Suspense } from "react"
import { Spin } from 'antd';

//生成路由
import { routerArr } from './router/routerArr';
import createRouter from './router/createRouter';

//全局状态管理
import { useDispatch } from 'react-redux';

function App() {

 
  return (
    <div className="App" style={{height:'100vh',backgroundColor:'#EEF0F6'}}>
      <Suspense fallback={<Spin />}>
        <Routes>
          {createRouter(routerArr)}
        </Routes>
      </Suspense>  
    </div>
  );
}

export default App;
