import { createSlice,configureStore } from "@reduxjs/toolkit";
import Cookies from "js-cookie";

const userInfo = () => {
    const cacheData = localStorage.getItem('userInfo');
    return cacheData ? JSON.parse(cacheData) : {};
};
// const userInfo = () => {
//     console.log('   === ', JSON.parse(storageData().user))
//     return storageData().user ? JSON.parse(storageData().user).userInfo : {}
// }
let userInfoSlice = createSlice({
    name:"userInfo",
    initialState:{
        token:Cookies.get('token'),
        email: userInfo()?.email,
        name:userInfo()?.username,
        vchain:[],
        orderId:'',
        cloud_wallet_user_id: userInfo()?.cloud_wallet_user_id,
        cloud_wallet_address: userInfo()?.cloud_wallet_address,
        cloud_contract_wallet: userInfo()?.cloud_contract_wallet,
        userInfo: userInfo()
    },
    reducers:{
        changeUserInfo(state,action) {
            state.userInfo = action.payload
        },
        changeToken(state,action){
            state.token = action.payload
            const isDev = process.env.NODE_ENV === 'development';
            Cookies.set('token', action.payload, {
                expires: 7,
                domain: !isDev ? process.env.REACT_APP_SUFFIX : undefined,
            });
        },
        changeEmail(state,action){
            state.email = action.payload
        },
        changeName(state,action){
            state.name = action.payload
        },
        changeVchain(state,action){
            state.vchain = action.payload
        },
        changeOrderId(state,action){
            state.orderId = action.payload
        }
    }
})

let requestAccountsSlice = createSlice({
    name:"requestAccounts",
    initialState:{
        accounts:[],
        balance:''
    },
    reducers:{
        changeAccounts(state,action){
            state.accounts = action.payload
        },
        setBalance(state,action){
            state.balance = action.payload
        }
    }
})

let sendAccountsSlice = createSlice({
    name:"sendAccounts",
    initialState:{
        accounts:[],
        clientSecret:''
    },
    reducers:{
        changeSendAccounts(state,action){
            state.accounts = action.payload
        },
        changeClientSecret(state,action){
            state.clientSecret = action.payload
        }
    }
})
let shopAccountSlice = createSlice({
    name:"shopAccount",
    initialState:{
        currentAccount: '',
        isConnect:'',
        chainId: '',
        shopInfo: [],
    },
    reducers:{
        changeShopInfo(state, action) {
            state.shopInfo = action.payload
        },
        changeShopAccount(state,action){
            state.currentAccount = action.payload
        },
        changeChainId(state,action){
            state.chainId = action.payload
        },
        changeShopIsConnect(state,action){
            state.isConnect = action.payload
        },
    }
})

let store = configureStore({
    reducer:{
        userInfoReducer:userInfoSlice.reducer,
        requestAccountsReducer:requestAccountsSlice.reducer,
        sendAccountsReducer:sendAccountsSlice.reducer,
        shopAccountReducer: shopAccountSlice.reducer,
    }
})

export default store
