import { Route } from "react-router-dom"
export default function createRouter(routerArr){
    return routerArr.map((item)=>{
        if(item.children && item.children.length>0){
            return <Route key={item.path} path={item.path} Component={item.component}>
                {createRouter(item.children)}
            </Route>
        }else{
            return <Route path={item.path} Component={item.component} key={item.path}> </Route>
        }
    })
}