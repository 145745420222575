import { request } from "./request";

//注册
export const signup = (params)=>{
    return request({
        url:'user/signup',
        method:"post",
        data:{
            ...params
        }
    })
}

//登录
export const login = (params)=>{
    return request({
        url:'user/login',
        method:"post",
        data:{
            ...params
        }
    })
}

//获取用户信息
export const userInfo = ()=>{
    return request({
        url:'user/info',
        method:"get",
    })
}

//登出
export const logout = ()=>{
    return request({
        url:'user/logout',
        method:"post",
    })
}

//验证码登录
export const loginWithCode = (params)=>{
    return request({
        url:'user/login_with_code',
        method:"post",
        data:{
            ...params
        }
    })
}

//修改密码
export const resetPassword = (params)=>{
    return request({
        url:'user/reset_password',
        method:"post",
        data:{
            ...params
        }
    })
}

//更新user信息
export const updateUser = (params)=>{
    return request({
        url:'user/update_user',
        method:"post",
        data:{
            ...params
        }
    })
}

//获取验证码
export const sendCode = (params)=>{
    return request({
        url:'verifycode/send_code',
        method:"post",
        data:{
            ...params
        }
    })
}

//邮箱查询
export const searchEmail = (params)=>{
    return request({
        url:'/user/search_email',
        method:"post",
        data:{
            ...params
        }
    })
}

//VchainList
export const VchainList = ()=>{
    return request({
        // url:'user/vchain/list',
        url:'user/vchain',
        method:"get",
    })
}

//VchainList
export const VchainSupport = ()=>{
    return request({
        url:'user/vchain/support',
        method:"get",
    })
}

//open_account
export const openAccount = (params)=>{
    return request({
        url:'user/vchain/open_account',
        method:"post",
        data:{
            ...params
        }
    })
}
/*
* order
*/

//create
export const OrderCreate = (params)=>{
    return request({
        url:'order/order_create',
        method:"post",
        data:{
            ...params
        }
    })
}

//queryUserId
export const queryUserId = (params)=>{
    return request({
        url:'user/fuzzy_search',
        method:"post",
        data:{
            ...params
        }
    })
}

//create
export const RequestOrderCreate = (params)=>{
    return request({
        url:'order/order_request_batch_create',
        method:"post",
        data:{
            ...params
        }
    })
}

//pay
export const OrderPay = (params)=>{
    return request({
        url:'order/order_pay',
        method:"post",
        data:{
            ...params
        }
    })
}

//history
export const OrderHistory = (params)=>{
    return request({
        url:'user/order_history',
        method:"post",
        data:{
            ...params
        }
    })
}

export const OrderActivity = (params)=>{
    return request({
        url:'order/order_activity',
        method:"post",
        data:{
            ...params
        }
    })
}

export const OrderActivityV2 = (params)=>{
    return request({
        url:'order/order_activity',
        method:"post",
        data:{
            ...params
        }
    })
}

//query
export const OrderQuery = (params)=>{
    return request({
        url:'user/order_query',
        method:"post",
        data:{
            ...params
        }
    })
}

//query
export const OrderActivityDetail = (params)=>{
    return request({
        url:'order/order_activity_detail',
        method:"post",
        data:{
            ...params
        }
    })
}

//cancel
export const OrderCancel = (params)=>{
    return request({
        url:'order/order_cancel',
        method:"post",
        data:{
            ...params
        }
    })
}


/*
* Vchain
*/

//VchainLink
export const VchainLink = (params)=>{
    return request({
        url:'user/vchain/link',
        method:"post",
        data:{
            ...params
        }
    })
}

//VchainUnlink
export const VchainUnlink = (params)=>{
    return request({
        url:'user/vchain/unlink',
        method:"post",
        data:{
            ...params
        }
    })
}


export const upload = (val)=>{
    const formData = new FormData()
    formData.append('upfile',val)

    return request({
        url:'upload/upload_file',
        method:"post",
        data:formData
    })
}