export const constants ={
    stripeConfig : 'pk_test_51OA016HQcnxWQkXXvKHF6m1SmA9XF15yTUTBu846lLa4raS1Bh2ExR8y2VX8vbeXzt038nk5KtbPOjzOHNDDQyPO00CyL96IbU',
    whiteList:[
        'user/signup',
        'user/login_with_code',
        'user/login',
        'verifycode/send_code',
        'order/order_create',
        'order/order_request_batch_create',
        'user/order_add_payers',
        'user/order_query',
        'order/order_activity_detail'
    ],
    reloadVchain:[
        'user/vchain/link',
        'user/vchain/unlink',
        'user/vchain/open_account',
    ],
}

export const CHAIN_MAP = {
    '1': 'Ethereum',
    '56': 'Binance Smart Chain',
    '137': 'Polygon',
    '43114': 'Avalanche',
    '250': 'Fantom',
    '1666600000': 'Harmony',
    '43113': 'Avalanche Testnet',
    '80001': 'Polygon Testnet',
    '97': 'Binance Smart Chain Testnet',
    '1287': 'Moonbase Alpha',
    '1285': 'Moonriver',
}
